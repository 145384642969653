<template>
  <!--
    二维码组件（前端生成的二维码，后端直接返回二维码可以在调整）
    1. 参数：
      - content 字符串内容，根据 content 生成二维码；每次打开弹窗会根据 content 生成；
      - title 弹窗的标题
   -->
  <el-dialog
    v-bind="{title, width: '230px'}"
    v-dialogDrag
    @open="handlerOpen"
    @closed="handlerClose"
    :visible.sync="visibleDialog">
    <div class="flex flex-column align-center justify-center">
      <div class="mb-10" ref="qrcode"></div>
      <el-button type="text" @click="saveAs">下载</el-button>
    </div>
  </el-dialog>
</template>

<script>
import QRCode from 'qrcodejs2'
export default {
  props: {
    visible: { type: Boolean, default: false, require: true },
    content: { type: String, default: '' },
    title: { type: String, default: '二维码' }
  },
  computed: {
    visibleDialog: {
      set (newValue) { this.$emit('update:visible', newValue) },
      get () { return this.visible }
    }
  },
  mounted () {

  },
  methods: {
    handlerOpen () {
      this.$nextTick(() => {
        const qrcode = new QRCode(this.$refs.qrcode, {
          width: 150, // 图像宽度
          height: 150, // 图像高度
          colorDark: '#000000', // 前景色
          colorLight: '#ffffff', // 背景色
          typeNumber: 4,
          correctLevel: QRCode.CorrectLevel.H // 容错级别 容错级别有：（1）QRCode.CorrectLevel.L （2）QRCode.CorrectLevel.M （3）QRCode.CorrectLevel.Q （4）QRCode.CorrectLevel.H
        })
        qrcode.clear() // 清除二维码
        qrcode.makeCode(this.content) // 生成另一个新的二维码
      })
    },
    handlerClose () {
      this.$refs.qrcode.innerHTML = ''
    },
    saveAs () {
      const myCanvas = this.$refs.qrcode.getElementsByTagName('canvas')
      const a = document.createElement('a')
      a.href = myCanvas[0].toDataURL('image/png')
      a.download = this.title
      a.click()
    }
  }
}
</script>

<style>

</style>
